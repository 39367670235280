/* Tailwind base imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import fonts (if not already in index.html) */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@300;400;600;700&display=swap');

/* Example variable-based approach (optional) */
:root {
  --color-bg: #ffffff;
  --color-text: #222222;
  --color-accent: #d4af37; /* gold */
}

/* Apply them if needed */
body {
  background-color: var(--color-bg);
  color: var(--color-text);
  font-family: "Open Sans", sans-serif;
}

/* Headings use Montserrat */
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

/* Example button styling override if desired */
button {
  transition: background-color 0.2s ease, color 0.2s ease;
}

/* Hover shadow effect on cards (already partially done in Tailwind) */
.bg-white.rounded-lg:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Loader style, if needed */
.loader {
  /* your loader/spinner styling here */
}

/* Example custom scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
